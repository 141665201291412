import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/proj_1.jpg'
import IMG2 from '../../assets/proj_2.jpg'
import IMG3 from '../../assets/proj_3.jpg'
import IMG4 from '../../assets/proj_4.jpg'
import IMG5 from '../../assets/proj_5.png'


// DO NOT USE THE IMAGES IN PRODUCTION

const  data = [
  {
    id: 1,
    image: IMG1,
    title: 'Healthcare Software - Rxnt Healthcare Software',
    
    website: 'https://www.rxnt.com/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Insurance Software - Arch Insurance',
    
    website: 'https://www.archgroup.com/'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Coaching Application - Coachhub',
    
    website: 'https://www.coachhub.com/'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Depot Management - MorgenFund',
    
    website: 'https://www.morgenfund.com/de'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Sport streaming Platform - Dyn Media',
    
    website: 'https://dynmedia.com/'
  },

]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Clients & Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, website}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
             
                <a href={website} className='btn btn-primary' target='_blank'>Live website</a>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio