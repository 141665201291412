import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.jpg'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h3>Hello I'm</h3>
        <h1>Shankar</h1>
        <h2 className="text-highlight">IT/Engineering Consultant</h2>
        {/* Hide Resume */}
        {/* <CTA /> */}
        <HeaderSocial />

        <div className="me">
          <img src={ME} alt="me" />
        </div>

        <a href="#contact" className='scroll__down text-highlight'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header
