import React from 'react'
import './about.css'
import ME from '../../assets/me-about.gif'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
    
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          {/* <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>10 Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>30+ Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>50+ Completed</small>
            </article>
          </div> */}
          <div>
          <p>
            I am a Certified Solution Architect with a decade experience in IT. With expertise in hybrid cloud technologies,
            I craft scalable and secure solutions for businesses.
            <br />
            <br/>
            As a passionate, business-driven enthusiast, I am always open to forming strategic partnerships that bring fresh perspectives, innovation, and collaborative energy to drive mutual success.
            <br />
            <br/>
          I'm excited to explore how we can achieve your tech goals together. Let's work together to bring your ideas to life and drive your business forward.
          </p>
          </div>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About